$ ->
  header_height = $(header).height()
  if $('header').hasClass('fixed')
    hero_height = $(window).height() - 50
    $('.hero').height(hero_height)

    $(window).scroll ->
      fade_limit = hero_height - header_height
      fade_start = hero_height - (4*header_height)
      console.log "header: #{header_height}, limit: #{fade_limit}, start: #{fade_start}, scrollY: #{scrollY}"

      alpha = 0
      if window.scrollY > fade_start
        if window.scrollY < fade_limit
          diff = (fade_limit-scrollY)/(fade_limit-fade_start)
          alpha = 1-diff
          # $('header').addClass('hover-helper') if !$('header').hasClass('hover-helper')
        else
          # $('header').removeClass('hover-helper')
          alpha = 1
      else
      $('header').css('background-color', "rgba(255,255,255,#{alpha})");

    if window.action_name not in ["weddings_and_groups", "rates_and_reservations"]# and $(window).width() > 922
      $('header').removeClass('hover-helper')
  else
    hero_height = $(window).height() - 50 - header_height
    $('.hero').height(hero_height)
