import validate from "jquery-validation";
$ ->
  $(".contact_form").validate
    ignore: '.ignore'
    errorElement: "i"
    errorClass: "error"
    errorPlacement: (error, element) ->
      error.appendTo(element.parent("div").prev("div"))
    # rules:
    #   hiddenRecaptcha: 
    #     required: ->
    #       if (grecaptcha.getResponse() == '')
    #         return true
    #       else
    #         return false
    submitHandler: (form) ->
      $.ajax
        url: '/pages/deliver_contact_form'
        data: $('form.contact_form').serialize()
        dataType: 'json'
        type: 'POST'
        success: ->
          $(".contact_form").hide()
          $(".js-email-instructions").hide()
          $(".js-email-success").show()
        complete: ->
          true
        error: (url, XMLHttpRequest, textStatus, errorThrown) ->
          alert("Error Sending Email");
      return false
